<template>
  <div>
    <el-radio-group v-model='historyType' size='large' class='mt-4' @change='getLpHistories' >
      <el-radio-button label='출자내역'></el-radio-button>
      <el-radio-button label='분배내역'></el-radio-button>
    </el-radio-group>
    <div class='flex flex-row my-1 mt-4'>
      <h4 class='text-xl'>{{ historyType }} 추가</h4>
      <button
        class='border rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        name='new-lp-history-data-form-btn'
        @click='addNewLpHistory'>
        <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
      </button>
    </div>
    <table class='text-base'>
      <thead class='font-semibold bg-gray-100'>
        <table-header-filter-select
            v-bind:filter-texts.sync='filterTexts.history_date'
            keyName='history_date'
            v-bind:sorting-dir.sync='sortDir'
            v-bind:sorting-key.sync='sortKey'
            :default-label='historyDate'
            :option-object='lpHistories'
            class='px-2 py-2 text-left border-r border-l' />
        <th class='px-2 py-2 text-center border-r border-l border-b border-t'>펀드명</th>
        <th v-if='isInvestment' class='px-2 py-2 text-center border-r border-b border-t'>출자금액</th>
        <th v-if='isDistribution' class='px-2 py-2 text-center border-r border-b border-t'>원금분배금액</th>
        <th v-if='isDistribution' class='px-2 py-2 text-center border-r border-b border-t'>이익분배금액</th>
      </thead>
      <tbody>
        <tr v-for='(lpHistory, index) in filteredData' :key='index'>
          <td class='px-2 border-r border-l border-b'
            @paste.prevent='onPasteData($event, index)'>
            <el-date-picker
              v-model='lpHistory.history_date'
              value-format='yyyy-MM-dd'
              type='date'
              placeholder='출자일'>
            </el-date-picker>
          </td>
          <td class='px-2 border-r border-b'>
            <input type='text' v-model='lpHistory.short_name' class='w-full text-left' />
          </td>
          <td v-if='isInvestment' class='px-2 border-r border-b'>
            <vue-numeric
              v-model='lpHistory.investment_value'
              output-type='number'
              class='form-input w-full text-right'
              :minus='true'
              separator=','></vue-numeric>
          </td>
          <td v-if='isDistribution' class='px-2 border-r border-b'>
            <vue-numeric
              v-model='lpHistory.principal'
              output-type='number'
              class='form-input w-full text-right'
              :minus='true'
              separator=','></vue-numeric>
          </td>
          <td v-if='isDistribution' class='px-2 border-r border-b'>
            <vue-numeric
              v-model='lpHistory.profit'
              output-type='number'
              class='form-input w-full text-right'
              :minus='true'
              separator=','></vue-numeric>
          </td>
        </tr>
      </tbody>
    </table>
    <div class='flex'>
      <button class='py-2 font-bold text-left border rounded-md border-transparent hover:border-gray-500'
        @click='uploadLpHistories'>Save Changes: </button>
      <label @click='result=""' class='px-4 py-2 inline-block text-left'>{{ result }}</label>
    </div>
  </div>
</template>

<script>
import { mapState }       from 'vuex'
import LpPortfoliosApi    from '@/api/v1/lp_portfolios'
import { PlusCircleIcon } from '@vue-hero-icons/outline'
import CopyPasteHelpers   from '@/utils/copy-paste-helpers'
import cloneDeep          from 'lodash/cloneDeep'
import TableHeaderFilterSelect from '@/components/TableHeaderFilterSelect.vue'

export default {
  name: 'LpHistoryEdit',
  components: {
    PlusCircleIcon,
    TableHeaderFilterSelect,
  },
  data () {
    return {
      lpHistories: [],
      result : '',
      historyType: '출자내역',
      sortDir: '',
      sortKey: '',
      filterTexts: {
        history_date: [],
      },
      filterKeys: [
        'history_date',
      ],
    }
  },
  watch: {
    'drilldownPortfolio.share_code': {
      handler: function (newCode) {
        if (newCode.length > 5) {
          this.getLpHistories ()
          this.result = ''
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('portfolios', [
      'drilldownPortfolio',
    ]),
    historyDate () {
      return this.historyType === '출자내역' ? '출자일자' : '분배일자'
    },
    isInvestment () {
      return this.historyType === '출자내역'
    },
    isDistribution () {
      return this.historyType === '분배내역'
    },
    sortedData () {
      if (this.sortDir !== '') {
        this.sortData ( {key: this.sortKey, direction: this.sortDir} )
      }

      return this.lpHistories
    },
    filteredData () {
      return this.sortedData.filter( data => this.inAnyData(data, this.filterKeys, this.filterTexts) )
    },
  },
  methods: {
    getLpHistories () {
      LpPortfoliosApi.getLpHistories({share_code: this.drilldownPortfolio.share_code, history_type:this.historyType}).then( resp => {
        this.lpHistories = resp.sort((a,b) => a.history_date > b.history_date ? 1 : -1)
      })
    },
    uploadLpHistories () {
      this.result = ''
      LpPortfoliosApi.createLpHistories({ share_code: this.drilldownPortfolio.share_code,
                                         history_type: this.historyType,
                                         lp_histories: this.lpHistories }).then( resp => {
        this.result = resp

        this.getLpHistories ()
      })
    },
    addNewLpHistory () {
      this.lpHistories.push ({ id: this.lpHistories.length, history_date: '',
                                investment_value: 0, principal: 0, profit: 0 })
    },
    onPasteData (data, targetIndex) {
      let paste = (data.clipboardData || window.clipboardData).getData('text')
      let pastedArray = CopyPasteHelpers.convertClipboardToArrayOfArrays(paste)

      let remainLength = this.lpHistories.length - targetIndex

      let ii
      for (ii = 0; ii <= pastedArray.length - remainLength; ii++) {
        this.addNewLpHistory ()
      }

      pastedArray.forEach((row, index) => {
        this.updateRow(targetIndex + index, row)
      })

      if (ii > 0) {
        this.lpHistories.pop()
      }
    },
    updateRow (rowIndex, dataArray) {
      let index = 0

      this.lpHistories[rowIndex].history_date = new Date(dataArray[index++])
      this.lpHistories[rowIndex].short_name = dataArray[index++]
      if (this.isInvestment) {
        this.lpHistories[rowIndex].investment_value = dataArray[index++]
      } else {
        this.lpHistories[rowIndex].principal = dataArray[index++]
        this.lpHistories[rowIndex].profit = dataArray[index++]
      }
    },
    inAnyData (data, filterKeys, filters) {
      if (filterKeys) {
        for (var ii = 0; ii < filterKeys.length; ii++) {
          var key = filterKeys[ii]
          if (!this.inAny (data[key], filters[key]))
            return false
        }
      }
      return true
    },
    inAny (source, filterTexts) {
      if (!filterTexts || filterTexts.length === 0)
        return true

      for (var ii = 0; ii < filterTexts.length; ii++) {
        if (source == filterTexts[ii])
          return true
      }
      return false
    },
    sortData (sortInfo) {
      let sortLpHistories = cloneDeep(this.lpHistories)
      sortLpHistories.sort((aSort, bSort) => {
        let aValue, bValue
        if (typeof aSort === 'string') {
          aValue = aSort[sortInfo.key].toUpperCase()
          bValue = bSort[sortInfo.key].toUpperCase()
        } else {
          aValue = aSort[sortInfo.key]
          bValue = bSort[sortInfo.key]
        }

        if (sortInfo.direction === 'asc') {
          if (aValue < bValue) {
            return -1
          } else if (aValue > bValue) {
            return 1
          } else {
            return 0
          }
        } else {
          if (aValue < bValue) {
            return 1
          } else if (aValue > bValue) {
            return -1
          } else {
            return 0
          }
        }
      })
      this.lpHistories = sortLpHistories
    },
  },
}
</script>
