<template>
  <tr @click='highlight' class='short-cell'>
    <td>
      <a class='hover:underline' :href='announcementLink' target='_blank'>
        {{ipo.name}}
        <external-link-icon class='h-4 mb-1 inline-block' />
      </a>
    </td>
    <td class='border-l' style='white-space: pre;'>{{brokerNames}}</td>
    <td class='border-l'>{{marketName}}</td>
    <td class='border-l'>
      <textarea
        v-model='ipoDetails.sector'
        @change='updateDetail("섹터")'
        rows='1'
        class='form-textarea'
        style='height: 2rem; width: 16rem; font-size: 14px;' />
    </td>
    <td class='border-l'>{{ipo.predicted_start_date}}</td>
    <td class='border-l'>{{ipo.listing_date}}</td>
    <td class='border-l text-right'>{{ipo.details['희망공모가액']}}</td>
    <td class='border-l text-right'>{{desiredOfferingAmount}}</td>
    <td class='border-l text-right'>{{offeringAmount}}</td>
    <td class='border-l text-right'>{{formattedOfferingQuantity}}</td>
    <td class='border-l text-right'>
      <vue-numeric
        v-model='ipoDetails.circulateQuantity'
        @change='updateDetail("유통가능주식수", "number")'
        output-type='number'
        class='form-input text-right'
        style='height: 2rem; width: 10rem; font-size: 14px;'
        empty-value=''
        currency='주'
        currency-symbol-position='suffix'
        separator=',' />
    </td>
    <td class='border-l text-right'>
      <vue-numeric
        v-model='ipoDetails.sharesOutstanding'
        @change='updateDetail("총발행주식수", "number")'
        output-type='number'
        class='form-input text-right'
        style='height: 2rem; width: 10rem; font-size: 14px;'
        empty-value=''
        currency='주'
        currency-symbol-position='suffix'
        separator=',' />
    </td>
    <td class='border-l text-right'>{{circulateRatio}}</td>
    <td class='border-l text-right'>
      <vue-numeric
        v-model='ipoDetails.estimatedPrice'
        @change='updateDetail("수요예측참여가", "number")'
        output-type='number'
        class='form-input text-right'
        style='height: 2rem; width: 10rem; font-size: 14px;'
        empty-value=''
        currency='원'
        currency-symbol-position='suffix'
        separator=',' />
    </td>
    <td class='border-l text-right'>{{formattedAllocatedPrice}}</td>
    <td class='border-l text-right'>{{formattedFixedOfferingAmount}}</td>
    <td class='border-l text-right'>{{ipo.details['기관경쟁률']}}</td>
    <td class='border-l text-right'>{{ipo.details['의무보유확약']}}</td>
    <td class='border-l text-right'>{{competitionRate}}</td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import groupBy from 'lodash/groupBy'
import { ExternalLinkIcon } from '@vue-hero-icons/outline'

export default {
  name: 'IpoInformationRow',
  components: {
    ExternalLinkIcon,
  },
  props: [
    'ipo',
  ],
  data () {
    return {
      ipoDetails: {
        sector: '',
        circulateQuantity: 0,
        sharesOutstanding: 0,
        estimatedPrice: 0,
      },
    }
  },
  watch: {
    'ipo.details': {
      handler: function (newVal) {
        if (newVal) {
          this.ipoDetails.sector = this.ipo.details['섹터'] ?? ''
          this.ipoDetails.circulateQuantity = parseFloat(this.ipo.details['유통가능주식수']) > 0 ? this.ipo.details['유통가능주식수'] : ''
          this.ipoDetails.sharesOutstanding = parseFloat(this.ipo.details['총발행주식수']) > 0 ? this.ipo.details['총발행주식수'] : ''
          this.ipoDetails.estimatedPrice = parseFloat(this.ipo.details['수요예측참여가']) > 0 ? this.ipo.details['수요예측참여가'] : ''
        }
      },
      immediate: true
    },
  },
  computed: {
    brokerNames () {
      return this.ipo.broker_ids.length > 0 ? Object.keys(groupBy(this.ipo.broker_accounts, 'broker_name')).filter(name => name !== 'null').join('\n') : ''
    },
    marketName () {
      switch (this.ipo.market) {
        case '거래소':
          return 'KOSPI'
        case '코스닥':
          return 'KOSDAQ'
        default:
          return this.ipo.market
      }
    },
    offeringPrice () {
      return this.ipo.details['희망공모가액'] ? this.ipo.details['희망공모가액'].replace('원', '').split('~') : '~'
    },
    lowestPrice () {
      return parseFloat(this.offeringPrice[0].replace(',', ''))
    },
    highestPrice () {
      return this.offeringPrice.length === 2 ? parseFloat(this.offeringPrice[1].replace(',', '')) : ''
    },
    offeringQuantity () {
      return this.ipo.details['총공모주식수'] ?? 0
    },
    formattedOfferingQuantity () {
      return this.offeringQuantity > 0 ? `${this.numberStyle(this.offeringQuantity)} 주` : ''
    },
    circulateQuantity () {
      return this.ipo.details['유통가능주식수'] ? this.ipo.details['유통가능주식수'] : 0
    },
    formattedEstimatedPrice () {
      return this.ipo.estimated_price ? `${this.numberStyle(this.ipo.estimated_price)} 원` : ''
    },
    allocatedPrice () {
      return this.ipo.details['확정공모가'] ? this.ipo.details['확정공모가'] : 0
    },
    formattedAllocatedPrice () {
      return this.allocatedPrice > 0 ? `${this.numberStyle(this.ipo.details['확정공모가'])} 원` : ''
    },
    sharesOutstanding () {
      return this.ipo.details['총발행주식수'] ? this.ipo.details['총발행주식수'] : 0
    },
    desiredOfferingAmount () {
      let lowestAmount = Math.round(this.sharesOutstanding * this.lowestPrice / 100000000, 0)
      let highestAmount = Math.round(this.sharesOutstanding * this.highestPrice / 100000000, 0)
      return this.sharesOutstanding === 0 ? '' : `${this.numberStyle(lowestAmount)}~${this.numberStyle(highestAmount)} 억`
    },
    offeringAmount () {
      let lowestAmount = Math.round(this.offeringQuantity * this.lowestPrice / 100000000, 0)
      let highestAmount = Math.round(this.offeringQuantity * this.highestPrice / 100000000, 0)
      return this.offeringQuantity === 0 ? '' : `${this.numberStyle(lowestAmount)}~${this.numberStyle(highestAmount)} 억`
    },
    fixedOfferingAmount () {
      return this.allocatedPrice * this.sharesOutstanding
    },
    formattedFixedOfferingAmount () {
      return this.fixedOfferingAmount > 0 ? `${this.numberStyle(Math.round(this.fixedOfferingAmount / 100000000, 0))} 억` : ''
    },
    circulateRatio () {
      return (this.circulateQuantity > 0 && this.sharesOutstanding > 0) ? `${(this.circulateQuantity / this.sharesOutstanding * 100).toFixed(2)} %` : ''
    },
    competitionRate () {
      if (this.ipo.details['청약경쟁률']) {
        return (this.ipo.details['청약경쟁률']).includes('비례') ? this.ipo.details['청약경쟁률'].split('(비례')[0] : this.ipo.details['청약경쟁률']
      } else {
        return ''
      }
    },
    announcementLink () {
      return this.ipo.details['증권신고서'] ? this.ipo.details['증권신고서'] : ''
    },
  },
  methods: {
    ...mapActions('ipos', [
      'patchIpoDetails',
      'updateIpoDetail',
    ]),
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
    highlight () {
      this.$emit('select-row', this.ipo.id)
    },
    updateDetail (key, type = 'text') {
      if (event) {
        this.updateIpoDetail( {id: this.ipo.id, key: key, type: type, value: event.target.value} )
        this.patchIpoDetails(this.ipo)
      }
    },
  },
}
</script>
