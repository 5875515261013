<template>
  <div>
    <div class='flex flex-row items-center justify-start gap-x-8 overflow-x-auto ipo-portfolio-controls py-2 lg:py-4'>
      <button
        class='flex flex-row justify-start items-center gap-x-1 whitespace-no-wrap py-2 pl-2 pr-2 border border-gray-200 text-sm rounded-md text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        name='new-entity-form-btn'
        @click='copyToClipboard'>
        <clipboard-copy-icon class='h-4' />
        <span class='text-xs'>Copy to Clipboard</span>
      </button>
    </div>
    <section class='ipo-table-container'>
      <table class='ipo-table border-l border-r'>
        <thead class='sticky top-0 bg-white'>
          <tr>
            <th class='border-b border-t' rowspan='2'>
              <div class='flex flex-row justify-between items-center'>
                <span>
                  종목명
                </span>
                <label class='flex flex-row justify-start items-center gap-x-1 whitespace-no-wrap'>
                  <input type='checkbox'
                    class='form-checkbox'
                    name='exceptSpac'
                    v-model='exceptSpac'>
                  <span class='text-xs'>스팩 제외</span>
                </label>
              </div>
            </th>
            <th class='border-b border-l border-t' rowspan='2'>주간사</th>
            <th class='border-b border-l border-t' rowspan='2'>시장구분</th>
            <th class='border-b border-l border-t' rowspan='2'>섹터</th>
            <th class='border-b border-l border-t text-center' colspan='4'>수요예측정보</th>
            <th class='border-b border-l border-t text-center' colspan='4'>주식수</th>
            <th class='border-b border-l border-t text-center' colspan='7'>수요예측결과</th>
          </tr>
          <tr class='text-left'>
            <th class='border-b border-l'>수요예측일</th>
            <th class='border-b border-l text-right'>상장일</th>
            <th class='border-b border-l text-right'>희망공모가액</th>
            <th class='border-b border-l text-right'>희망공모시총</th>
            <th class='border-b border-l text-right'>공모금액</th>
            <th class='border-b border-l text-right'>총공모주식수</th>
            <th class='border-b border-l text-right'>유통가능주식수</th>
            <th class='border-b border-l text-right'>총발행주식수</th>
            <th class='border-b border-l text-right'>유통가능비율</th>
            <th class='border-b border-l text-right'>수요예측참여가</th>
            <th class='border-b border-l text-right'>확정공모가</th>
            <th class='border-b border-l text-right'>확정공모시총</th>
            <th class='border-b border-l text-right'>기관경쟁률</th>
            <th class='border-b border-l text-right'>의무보유확약</th>
            <th class='border-b border-l text-right'>청약경쟁률</th>
          </tr>
        </thead>
        <tbody v-for='ipo in filteredData' :key='ipo.id'>
          <ipo-information-row :ipo='ipo'
            :style='selectedRow(ipo.id)'
            @select-row='selectRow' />
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { ClipboardCopyIcon } from '@vue-hero-icons/outline'
import IpoInformationRow from '@/views/ipo/IpoInformationRow.vue'

export default {
  name: 'IpoInformation',
  components: {
    ClipboardCopyIcon,
    IpoInformationRow,
  },
  data () {
    return {
      selectedIpoId: 0,
      exceptSpac: true,
    }
  },
  computed: {
    ...mapFields('ipos', [
      'ipos',
    ]),
    filteredData () {
      return this.exceptSpac ? this.ipos.filter(ipo => !(ipo.name.includes('스팩') || ipo.name.includes('기업인수'))) : this.ipos
    },
  },
  methods: {
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
    selectedRow (ipoId) {
      return (this.selectedIpoId == ipoId) ? 'background-color: lightyellow' : ''
    },
    selectRow (ipoId) {
      this.selectedIpoId = ipoId
    },
    copyToClipboard () {
      let headers = ['종목명', '주간사', '시장구분', '섹터', '수요예측일', '상장일', '희망공모가액', '희망공모시총', '공모금액', '총공모주식수', '유통가능주식수', '총발행주식수', '유통가능비율', '수요예측참여가', '확정공모가', '확정공모시총', '기관경쟁률', '의무보유확약', '청약경쟁률']
      let result = `${headers.join('\t')}\n`
      this.filteredData.forEach(ipo => {
        let marketName = ipo.market === '거래소' ? 'KOSPI' : ipo.market === '코스닥' ? 'KOSDAQ' : ipo.market
        let offeringQuantity = ipo.details['총공모주식수'] ?? 0
        let circulateQuantity = ipo.details['유통가능주식수'] ?? 0
        let sharesOutstanding = ipo.details['총발행주식수'] ?? 0

        let offeringPrice = ipo.details['희망공모가액'] ? ipo.details['희망공모가액'].replace('원', '').split('~') : '~'
        let lowestPrice = parseFloat(offeringPrice[0].replace(',', ''))
        let highestPrice = offeringPrice.length === 2 ? parseFloat(offeringPrice[1].replace(',', '')) : ''
        let desiredOfferingLowestAmount = Math.round(sharesOutstanding * lowestPrice / 100000000, 0)
        let desiredOfferingHighestAmount = Math.round(sharesOutstanding * highestPrice / 100000000, 0)
        let desiredOfferingAmount = sharesOutstanding === 0 ? '' : `${this.numberStyle(desiredOfferingLowestAmount)}~${this.numberStyle(desiredOfferingHighestAmount)} 억`

        let offeringLowestAmount = Math.round(offeringQuantity * lowestPrice / 100000000, 0)
        let offeringHighestAmount = Math.round(offeringQuantity * highestPrice / 100000000, 0)
        let offeringAmount = offeringQuantity === 0 ? '' : `${this.numberStyle(offeringLowestAmount)}~${this.numberStyle(offeringHighestAmount)} 억`

        let circulateRatio = (circulateQuantity > 0 && sharesOutstanding > 0) ? `${(circulateQuantity / sharesOutstanding * 100).toFixed(2)} %` : '' // 유통가능비율

        let estimatedPrice = ipo.details['수요예측참여가'] ?? 0
        let allocatedPrice = ipo.details['확정공모가'] ?? 0
        let fixedOfferingAmount = allocatedPrice * sharesOutstanding
        let formattedFixedOfferingAmount = fixedOfferingAmount > 0 ? `${this.numberStyle(Math.round(fixedOfferingAmount / 100000000, 0))} 억` : ''

        let competitionRate
        if (ipo.details['청약경쟁률']) {
          competitionRate = (ipo.details['청약경쟁률']).includes('비례') ? ipo.details['청약경쟁률'].split('(비례')[0] : ipo.details['청약경쟁률']
        } else {
          competitionRate = ''
        }

        let rawData = [
          ipo.name,
          ipo.details['주간사'],
          marketName,
          (ipo.details['섹터'] ?? '').replace(/\n/g, ' '),
          ipo.predicted_start_date, // 수요예측일
          ipo.listing_date, // 상장일
          ipo.details['희망공모가액'], // 희망공모가액
          desiredOfferingAmount, // 희망공모시총
          offeringAmount, // 공모금액
          this.numberStyle(offeringQuantity),
          this.numberStyle(circulateQuantity),
          this.numberStyle(sharesOutstanding),
          circulateRatio, // 유통가능비율
          this.numberStyle(estimatedPrice), // 수요예측참여가
          this.numberStyle(allocatedPrice), // 확정공모가
          formattedFixedOfferingAmount, // 확정공모시총
          ipo.details['기관경쟁률'],
          ipo.details['의무보유확약'],
          competitionRate,
        ]
        result += `${rawData.join('\t')}\n`
      })
      this.$copyText(result).then(() => {
        this.$message({
          type: 'success',
          message: '클립보드에 복사 됨.',
        })
      })
    },
  }
}
</script>

<style lang='scss' scoped>

  table {
    border-collapse: separate;
  }
  .ipo-table-container {
    @apply overflow-auto;
    width: 100%;
    height: calc(100vh - 9rem);
  }

  @media (min-width: 1024px) {
    .ipo-table-container {
      height: calc(100vh - 11rem);
    }
  }

</style>
